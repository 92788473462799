import {
  postBodyRequest, exportRequest
} from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/trans/finance/quality/loss/detail/page', params)
}

export function detailExport (params, fileName) {
  return exportRequest('/trans/finance/quality/loss/detail/export', params, fileName)
}
