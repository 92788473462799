<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-row :gutter="[24,12]">
          <a-col :span="6">
            <a-form-item label="小车车牌" name="carNo">
              <a-input v-model:value="searchForm.carNo" allowClear />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="质损结算方式" name="carNo">
              <a-select v-model:value="searchForm.lossType" style="width:150px" placeholder="请选择" :options="lossTypeOpt" allowClear />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="上报时间">
              <a-range-picker v-model:value="searchForm.explainTime" valueFormat="YYYY-MM-DD" :format="dateFormat" @change="onRange()" allowClear />
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item>
              <a-button type="primary" @click="onSearch">
                <template #icon>
                  <SearchOutlined />
                </template>查询
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item>
              <a-button type="primary" @click="detailExport()">
                <template #icon>
                  <SearchOutlined />
                </template>导出
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
    </template>
    <a-table :columns="[sortColumn,...columns]" :rowKey="(record,index)=>{return index}" :data-source="listData" :pagination="pagination" :loading="loading" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{index+1}}
      </template>
    </a-table>
  </suy-table>
</template>
<script>
import { toRefs, reactive, onMounted, ref } from 'vue'
import { page, detailExport as dExport } from '@/api/transport/qualityLoss'
import SuyTable from '@/components/SuyTable'
import transportType from '../TransportType/TransportType.json'
import { message } from 'ant-design-vue'
// import { getOrgList } from '@/api/global'
import { useStore } from 'vuex'
export default {
  data () {
    return {
      exceptionExplains: transportType.exceptionExplain,
      exceptionTypes: transportType.exceptionType

    }
  },
  components: {
    SuyTable
  },

  setup () {
    const store = useStore()
    const state = reactive({
      carIMG: ref(null),
      addi: ref(null),
      loading: false,
      orgList: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        exceptionSubType: '',
        exceptionType: '',
        explainer: '',
        carNo: '',
        explainTime: '',
        lossType: null
      },
      listData: [],
      sortColumn: { title: '序号', width: 50, slots: { customRender: 'sort' } },
      lossTypeOpt: [{
        label: '现金',
        value: 0
      },
      {
        label: '抵扣',
        value: 1
      },
      ],
      columns: [
        {
          title: '发起时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '发起人',
          dataIndex: 'sponsor',
          key: 'sponsor'
        },
        {
          title: '发起部门',
          dataIndex: 'sponsorOrgName',
          key: 'sponsorOrgName'
        },
        {
          title: '质损流水',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: '车辆车架号',
          dataIndex: 'orderVehicleVin',
          key: 'orderVehicleVin'
        },
        {
          title: '质损类型',
          dataIndex: 'qualityLossType',
          key: 'qualityLossType',
          ellipsis: true
        },
        {
          title: '质损结算方式',
          dataIndex: 'lossType.label',
          key: 'qualityLossType',
          ellipsis: true
        },
        {
          title: '责任类型',
          dataIndex: 'responsibilityType.label',
          key: 'responsibilityType.label'
        },
        {
          title: '责任对象',
          dataIndex: 'objectName',
          key: 'objectName',
          ellipsis: true
        },
        {
          title: '人员',
          dataIndex: 'personName',
          key: 'personName'
        },
        {
          title: '金额',
          dataIndex: 'amt',
          key: 'amt'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        }
      ],
      form: {},
      getname: ''
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const onClick = (recode, type) => {
      switch (type) {
        case 1:
          if (recode == null) {
            recode = {}
          }
          break
        case 2:
          message.success('开发中' + type)
          break
        case 3:
          state.addi.onSwitch(true)
          break
        case 4:
          state.addi.onData(recode)
          break
        case 5:
          // dele(recode.id, state.data).then((res) => {
          //   if (res.code === 10000) {
          //     message.success(res.msg)
          //   }
          //   state.fromRef.resetFields()
          // })
          loadData()

          break
        default:
          message.success('出现错误' + type)
      }
    }
    const onRange = () => {
      if (state.searchForm.explainTime.length === 0) {
        state.searchForm.startTime = ''
        state.searchForm.endTime = ''
      } else {
        state.searchForm.startTime = state.searchForm.explainTime[0]
        state.searchForm.endTime = state.searchForm.explainTime[1]
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const detailExport = () => {
      dExport({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }, '质损').then(res => {
        console.log('res', res)
      })
    }
    // getOrgList({}).then(res => { state.orgList = res.data })
    state.orgList = store.state.app.orgList
    return {
      ...toRefs(state),
      onSearch,
      loadData,
      handleTableChange,
      onClick,
      detailExport,
      onRange
    }
  }
}
</script>
